import { useQueries, useQueryClient } from '@tanstack/react-query';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import DailyWorkService from '../services/DailyWorkService';
import { ModuleName } from '../types/api/response/module';
import { UserResponse } from '../types/api/response/user';
import type { UserDailyWork } from '../types/front/dailyWork';
import type { Period } from '../types/types';

type Props = {
  users: UserResponse[];
  period: Period;
};

export default function useActivities({ period, users }: Props) {
  const queryClient = useQueryClient();
  const [debouncedUsers, setDebouncedUsers] = useState<UserResponse[]>([]);
  const month = period.start.month() + 1;
  const year = period.start.year();

  useEffect(() => {
    const debouncedUpdate = debounce(() => {
      setDebouncedUsers(
        users.filter((user) => {
          return (
            user.valid &&
            !!user.paidFeatures?.find(
              (item) => item.name === ModuleName.DailyWork && item.active
            )
          );
        })
      );
    }, 1000);

    debouncedUpdate();

    return () => {
      debouncedUpdate.cancel();
    };
  }, [users]);

  const userDailyWorkQueries = useQueries({
    queries: debouncedUsers.map((user) => {
      return {
        queryKey: ['DailyWorkService.getUserDailyWork', user.id, month, year],
        queryFn: () => DailyWorkService.getUserDailyWork(user.id, month, year),
      };
    }),
  });

  const dataArray = userDailyWorkQueries
    .filter((item) => !!item && !!item.data)
    .map((item) => item.data);

  const usersActivities: UserDailyWork[] = useMemo(() => {
    if (!dataArray.length) return [];

    const result: UserDailyWork[] = [];

    debouncedUsers.forEach((user) => {
      const dailyWorks = dataArray.find(
        (elt) => !!elt?.length && elt[0].user.id === user.id
      );
      if (dailyWorks?.length) {
        result.push({
          userId: user.id,
          status:
            dailyWorks.find((dailyWork) => dailyWork.status === 'WAITING') !==
            undefined
              ? 'WAITING'
              : 'VALID',
          employeeName: user.fullname!,
          dailyWorks,
        });
      }
    });

    return result.sort((a, b) => a.employeeName.localeCompare(b.employeeName));
  }, [debouncedUsers]);

  function refetch() {
    queryClient.invalidateQueries({
      queryKey: ['DailyWorkService.getUserDailyWork'],
    });
  }

  return {
    usersActivities,
    refetch,
    loading: debouncedUsers.length !== dataArray.length,
  };
}
