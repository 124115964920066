import { Button, Group, NumberInput, Tooltip } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import * as React from 'react';

import { useI18n } from '../../../../../contexts/I18nProvider';
import CounterUserService from '../../../../../services/CounterUserService';
import type { ManualIncrementCounterPayload } from '../../../../../types/api/payload/counterUser';
import {
  CounterRule,
  CounterType,
} from '../../../../../types/api/response/counterType';

type Props = {
  endMonthDate: dayjs.Dayjs;
  counterType: CounterType;
  userId: string;
};

type FormValues = {
  value: number;
};

export default function IncrementForm({
  endMonthDate,
  counterType,
  userId,
}: Props) {
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const form = useForm<FormValues>({
    initialValues: {
      value:
        counterType.rule === CounterRule.OPEN_DAY
          ? 2.08
          : counterType.rule === CounterRule.WORKING_DAY
          ? 2.5
          : 0,
    },
  });

  const { mutate } = useMutation({
    mutationFn: (variables: ManualIncrementCounterPayload) =>
      CounterUserService.manualIncrementCounter(userId, variables),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['CounterUserService.getByUser', userId],
      });
      showNotification({
        id: `manual-increment-user-counter-success-${userId}-${counterType.id}`,
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: () => {
      showNotification({
        id: `manual-increment-user-counter-error-${userId}-${counterType.id}`,
        message: t('w.error'),
        color: 'red',
        icon: <IconCheck />,
      });
    },
  });

  function incrementAndUpdateClick() {
    mutate({
      counterTypeId: counterType.id,
      value: form.values.value,
      date: endMonthDate.format('DD-MM-YYYY'),
    });
  }

  return (
    <Group grow align="end" justify="center" mt="xs">
      <NumberInput size="xs" min={0} {...form.getInputProps('value')} />
      <Tooltip
        label={t(
          'counterUser.manual.incrementAndUpdateAt',
          endMonthDate.format('DD/MM/YYYY')
        )}
      >
        <Button
          size="xs"
          variant="light"
          onClick={incrementAndUpdateClick}
          disabled={
            form.values.value === null ||
            form.values.value === undefined ||
            form.values.value?.toString() === ''
          }
        >
          {t('w.increment')}
        </Button>
      </Tooltip>
    </Group>
  );
}
