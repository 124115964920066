import type { CounterTypeResponse } from '../types/api/response/counterType';
import type {
  PayslipExtractLocation,
  PayslipExtractLocationPayload,
  PayslipLocation,
} from '../types/types';

export const PAYSLIP_CONFIG_COLORS = {
  name: '#800000',
  periodImported: '#9A6324',
  matricule: '#e6194B',
  codeCompany: '#f58231',
  siret: '#ffe119',
  grossAmount: '#469990',
  netAmount: '#000075',
  acquisN: '#f032e6',
  acquisN1: '#42d4f4',
  counterColors: [
    '#4363d8',
    '#3cb44b',
    '#fabed4',
    '#fffac8',
    '#aaffc3',
    '#dcbeff',
  ],
};

export const STATIC_FIELDS = Object.keys(PAYSLIP_CONFIG_COLORS).filter(
  (e) => e !== 'counterColors'
);

export type PayslipConfigurationFormObject = {
  id: string;
  color?: string;
  height?: number;
  width?: number;
  x?: number;
  y?: number;
};

export function getColorByKey(
  key: string,
  counterTypes: CounterTypeResponse[]
) {
  if (STATIC_FIELDS.includes(key))
    // @ts-ignore
    return PAYSLIP_CONFIG_COLORS[key];

  const counterTypesKeys = counterTypes
    .map((item) => item.counterType.key)
    .filter((elt) => elt !== 'acquisN' && elt !== 'acquisN1');
  const index = counterTypesKeys.findIndex((item) => item === key);

  return index !== -1 && index < 6
    ? PAYSLIP_CONFIG_COLORS.counterColors[index]
    : '#000000';
}

export function dataToFormData(
  data: PayslipExtractLocation
): Map<string, PayslipConfigurationFormObject> {
  const map: Map<string, PayslipConfigurationFormObject> = new Map();

  if (data.name?.location) {
    map.set('name', {
      id: 'name',
      color: PAYSLIP_CONFIG_COLORS.name,
      height: data.name.location?.height,
      width: data.name.location?.width,
      x: data.name.location?.x,
      y: data.name.location?.y,
    });
  }
  if (data.periodImported?.location) {
    map.set('periodImported', {
      id: 'periodImported',
      color: PAYSLIP_CONFIG_COLORS.periodImported,
      height: data.periodImported.location?.height,
      width: data.periodImported.location?.width,
      x: data.periodImported.location?.x,
      y: data.periodImported.location?.y,
    });
  }
  if (data.matricule?.location) {
    map.set('matricule', {
      id: 'matricule',
      color: PAYSLIP_CONFIG_COLORS.matricule,
      height: data.matricule.location?.height,
      width: data.matricule.location?.width,
      x: data.matricule.location?.x,
      y: data.matricule.location?.y,
    });
  }
  if (data.codeCompany?.location) {
    map.set('codeCompany', {
      id: 'codeCompany',
      color: PAYSLIP_CONFIG_COLORS.codeCompany,
      height: data.codeCompany.location?.height,
      width: data.codeCompany.location?.width,
      x: data.codeCompany.location?.x,
      y: data.codeCompany.location?.y,
    });
  }
  if (data.siret?.location) {
    map.set('siret', {
      id: 'siret',
      color: PAYSLIP_CONFIG_COLORS.siret,
      height: data.siret.location?.height,
      width: data.siret.location?.width,
      x: data.siret.location?.x,
      y: data.siret.location?.y,
    });
  }
  if (data.grossAmount?.location) {
    map.set('grossAmount', {
      id: 'grossAmount',
      color: PAYSLIP_CONFIG_COLORS.grossAmount,
      height: data.grossAmount.location?.height,
      width: data.grossAmount.location?.width,
      x: data.grossAmount.location?.x,
      y: data.grossAmount.location?.y,
    });
  }
  if (data.netAmount?.location) {
    map.set('netAmount', {
      id: 'netAmount',
      color: PAYSLIP_CONFIG_COLORS.netAmount,
      height: data.netAmount.location?.height,
      width: data.netAmount.location?.width,
      x: data.netAmount.location?.x,
      y: data.netAmount.location?.y,
    });
  }

  let index = 0;
  data.counter?.forEach((item) => {
    if (item.location) {
      switch (item.key) {
        case 'acquisN':
          map.set('acquisN', {
            id: 'acquisN',
            color: PAYSLIP_CONFIG_COLORS.acquisN,
            height: item.location.height,
            width: item.location.width,
            x: item.location.x,
            y: item.location.y,
          });
          break;
        case 'acquisN1':
          map.set('acquisN1', {
            id: 'acquisN1',
            color: PAYSLIP_CONFIG_COLORS.acquisN1,
            height: item.location.height,
            width: item.location.width,
            x: item.location.x,
            y: item.location.y,
          });
          break;
        default:
          map.set(item.key, {
            id: item.key,
            color: PAYSLIP_CONFIG_COLORS.counterColors[index]
              ? PAYSLIP_CONFIG_COLORS.counterColors[index]
              : '#000000',
            height: item.location.height,
            width: item.location.width,
            x: item.location.x,
            y: item.location.y,
          });
          index++;
      }
    }
  });

  return map;
}

export function formDataToPayload(
  companyId: string,
  counterTypes: CounterTypeResponse[],
  formData: Map<string, PayslipConfigurationFormObject>
): PayslipExtractLocationPayload {
  let data: PayslipExtractLocationPayload = {
    companyId,
  };

  const counter: PayslipLocation[] = [];

  formData.forEach((value: PayslipConfigurationFormObject, key: string) => {
    if (
      value.x != null &&
      value.y != null &&
      value.width != null &&
      value.height != null
    ) {
      const payslipLocation: PayslipLocation = {
        key,
        counterTypeId: counterTypes.find((item) => item.counterType.key === key)
          ?.counterType?.id,
        location: {
          x: Math.round(value.x),
          y:
            value.height > 0
              ? Math.round(value.y)
              : Math.round(value.y) + Math.round(value.height),
          width: Math.round(value.width),
          height: Math.abs(Math.round(value.height)),
        },
      };

      switch (value.id) {
        case 'name':
        case 'periodImported':
        case 'matricule':
        case 'codeCompany':
        case 'siret':
        case 'grossAmount':
        case 'netAmount':
          data = { ...data, [key]: payslipLocation };
          break;
        default:
          counter.push(payslipLocation);
      }
    }
  });

  if (counter?.length) {
    data.counter = counter;
  }

  return data;
}
