export type CommonEntity = {
  id: string;
  created?: LocalDateTimeString;
  updated?: LocalDateTimeString;
};

/**
 * format : 2024-09-16T08:47:33.061
 */
export type LocalDateTimeString = string;

/**
 * format : 2024-09-16 : yyyy-MM-dd
 */
export type LocalDateString = string;

/**
 * format : 16-09-2024 : dd-MM-yyyy
 */
export type DateStringRequest = string;

/**
 * format : 16/09/2024 : dd/MM/yyyy
 */
export type DateStringRequest2 = string;

/**
 * format : 01-2024 : MM-yyyy
 */
export type YearMonth = string;

/**
 * format : 13:00 : HH:mm
 */
export type HourMinute = string;

/**
 * format : dd/MM/yyyy HH:mm
 */
export type DateTimeStringRequest = string;

export enum DayOfWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export type Address = {
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  zipCode?: string | null;
};

export type Accountant = {
  contacts: Contact[];
};

export type Contact = {
  id: string;
  name: string;
  address: string;
  email: string;
  phone: string;
};
