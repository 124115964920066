import { Group, Pill } from '@mantine/core';

import type { DivisionResponse } from '../../../../types/api/response/division';
import type { EndOfMonthValidationResponse } from '../../../../types/api/response/endMonth';

type Props = {
  divisions: DivisionResponse[];
  endOfMonthValidation: EndOfMonthValidationResponse[];
};

export default function EndOfMonthDivisionsToValidate({
  divisions,
  endOfMonthValidation,
}: Props) {
  const endOfMonthsNotValidated = endOfMonthValidation.filter(
    (endOfMonth) => !endOfMonth.valid
  );

  function getDivisionName(divisionId: string): string | undefined {
    const findDivision = divisions.find(
      (division) => division.id === divisionId
    );
    if (findDivision) {
      return findDivision.name;
    }
  }

  return (
    <Group>
      {endOfMonthsNotValidated.map((item) => (
        <Pill key={item.divisionId}>{getDivisionName(item.divisionId)}</Pill>
      ))}
    </Group>
  );
}
