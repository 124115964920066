import type {
  ManualIncrementCounterPayload,
  UpdateCounterUserPayload,
} from '../types/api/payload/counterUser';
import type {
  CounterUser,
  CounterUserResponse,
} from '../types/api/response/counterUser';
import { MS_PLANNING_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function getByUser(userId: string): Promise<CounterUserResponse[]> {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/counter/user/${userId}`)
    .then((res) => res?.data);
}

function update(
  userId: string,
  params: UpdateCounterUserPayload
): Promise<CounterUser> {
  return request
    .patch(`${MS_PLANNING_ENDPOINT}/v2/counter/user/${userId}`, {}, { params })
    .then((res) => res?.data);
}

function exportUsersCounters(companyId: string): Promise<any> {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/counter/user/${companyId}/export/XLSX`, {
      responseType: 'blob',
    })
    .then((res) => res?.data);
}

function manualIncrementCounter(
  userId: string,
  params: ManualIncrementCounterPayload
): Promise<void> {
  return request
    .patch(
      `${MS_PLANNING_ENDPOINT}/v2/counter/user/${userId}/manual-increment-counter`,
      {},
      { params }
    )
    .then((res) => res?.data);
}

const CounterUserService = {
  getByUser,
  update,
  exportUsersCounters,
  manualIncrementCounter,
};

export default CounterUserService;
