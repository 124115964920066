import {
  Button,
  Center,
  Container,
  Divider,
  Flex,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Space,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import CustomLink from '../../components/CustomLink';
import LabelText from '../../components/LabelText/LabelText';
import Logo from '../../components/Logo';
import { useAuth } from '../../contexts/AuthProvider';
import { useI18n } from '../../contexts/I18nProvider';
import { useModule } from '../../contexts/ModuleProvider';
import LocaleSwitcher from '../../core/layouts/components/LocaleSwitcher';
import type { LoginResponse } from '../../hooks/useHfwLogin';
import useHfwLogin from '../../hooks/useHfwLogin';
import { ModuleName } from '../../types/api/response/module';
import { ACCOUNTANT_MODE, COMPANY_MODE } from '../../types/types';
import { persistData } from '../../utils/localStorage';
import InputEmail from './components/InputEmail';
import LoginStepper from './components/LoginStepper';
import ProfileChoiceModal from './components/ProfileChoiceModal';
import GmaLogin from './components/sso/GmaLogin';
import GoogleLogin from './components/sso/GoogleLogin';
import MicrosoftLogin from './components/sso/MicrosoftLogin';

export type ProviderProps = {
  setOpenProfileChoice: (openProfileChoice: boolean) => void;
  setAuthInfo: (loginResponse: LoginResponse) => void;
};

export default function Login() {
  const { t } = useI18n();
  const { updateUser, redirectWithAccessTokenParam } = useAuth();
  const { getModule, modules } = useModule();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width: 769px)');
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : t('invalid.email')),
    },
  });
  const {
    login,
    loading,
    error,
    loginResponse,
    oauthUserProfileResponse,
    accessToken,
    refreshToken,
  } = useHfwLogin(form.values.email, form.values.password);
  const [openProfileChoice, setOpenProfileChoice] = useState(false);
  const [authInfo, setAuthInfo] = useState<LoginResponse | undefined>(
    undefined
  );
  const isAccountantActiveModule = getModule(
    ModuleName.CharteredAccountant
  )?.active;

  useEffect(() => {
    if (loginResponse) {
      if (loginResponse.users?.length) {
        if (loginResponse.users.length > 1) {
          setAuthInfo(loginResponse);
          setOpenProfileChoice(true);
        } else {
          updateUser(
            loginResponse.users[0],
            loginResponse.access_token,
            loginResponse.refresh_token,
            searchParams.get('redirectUri')
          );
        }
      }
    } else {
      if (!!searchParams.get('redirectUri') && accessToken && refreshToken) {
        if (!searchParams.get('redirectUri')?.includes('/home')) {
          redirectWithAccessTokenParam(
            searchParams.get('redirectUri'),
            accessToken,
            refreshToken
          );
        }
      } else {
        if (
          Boolean(oauthUserProfileResponse) &&
          !Boolean(oauthUserProfileResponse?.currentProfile)
        ) {
          modals.open({
            title: (
              <Flex direction={'column'}>
                <Title size={'h3'}>
                  {t(
                    'w.congratsYourAccountSuccessfullyCreated',
                    oauthUserProfileResponse?.firstname
                  )}
                </Title>
                <Text fz={'sm'} fw={'bold'} c={'dimmed'}>
                  {t('w.aLittleMoreEffortToFinalizeSubscription')}
                </Text>
              </Flex>
            ),
            size: '90%',
            fullScreen: matches,
            children: (
              <LoginStepper
                accessToken={accessToken}
                oAuthUser={oauthUserProfileResponse}
                refreshToken={refreshToken}
                onModalClose={modals.closeAll}
              />
            ),
          });
        }
      }
    }
  }, [loginResponse, oauthUserProfileResponse, refreshToken, accessToken]);

  useEffect(() => {
    if (modules?.length) {
      if (isAccountantActiveModule) {
        persistData('mode', ACCOUNTANT_MODE);
        navigate('/chartered-accountant/market-place');
      } else {
        persistData('mode', COMPANY_MODE);
        navigate('/home');
      }
    }
  }, [modules]);

  useEffect(() => {
    if (error.isError) {
      showNotification({
        id: 'login-error',
        title: t('w.authentificationError'),
        message: t(
          `error.${error.details.response?.data?.error_key}`,
          form.values.email
        ),
        color: 'red',
        icon: <IconX />,
      });
    }
  }, [error]);

  function renderLink(navigation: string, text: string) {
    return <CustomLink navigation={navigation} text={text} />;
  }

  return (
    <Container
      display={'grid'}
      size={'xs'}
      styles={{
        root: {
          height: '100%',
          alignContent: 'center',
        },
      }}
    >
      <Space h="xs" />
      <Center>
        <Flex direction="column" align="center" gap="md">
          <Logo />
          <LocaleSwitcher useLocalStorage />
        </Flex>
      </Center>
      <Space h="xl" />
      <Paper radius="md" p="xl" withBorder shadow={'md'}>
        <LoadingOverlay visible={loading} />
        <Center>
          <Title order={3} size={'h3'} c={'hifivework.9'}>
            {t('w.login')}
          </Title>
        </Center>
        <Space h="sm" />
        <form onSubmit={form.onSubmit(login)}>
          <Stack>
            <InputEmail
              value={form.values.email}
              onChange={(event) =>
                form.setFieldValue('email', event.currentTarget.value)
              }
              error={form.errors.email}
            />
            <PasswordInput
              required
              label={<LabelText text={t('w.password')} />}
              placeholder={t('w.yourPassword')}
              value={form.values.password}
              onChange={(event) =>
                form.setFieldValue('password', event.currentTarget.value)
              }
            />
            <Space h="xs" />
            <Button type="submit">{t('w.login')}</Button>
            <Center>
              {renderLink('/forgot-password', t('w.forgottenMyPassword'))}
            </Center>
            <Divider
              label={<Text c={'gray.6'}>{t('w.or').toUpperCase()}</Text>}
              labelPosition="center"
              my="sm"
            />
            <Flex gap="xs" align="center" direction="column">
              <GoogleLogin
                setOpenProfileChoice={setOpenProfileChoice}
                setAuthInfo={setAuthInfo}
              />
              <MicrosoftLogin
                setOpenProfileChoice={setOpenProfileChoice}
                setAuthInfo={setAuthInfo}
              />
              <GmaLogin
                setOpenProfileChoice={setOpenProfileChoice}
                setAuthInfo={setAuthInfo}
              />
            </Flex>
          </Stack>
        </form>
      </Paper>
      <Space h="md" />
      <Center>
        <Flex gap={'xs'}>
          <Text fz={12}>{t('w.noAccountYet')}</Text>
          {renderLink('/sign-up', t('w.signUp'))}
        </Flex>
      </Center>
      <Space h="md" />
      <ProfileChoiceModal
        open={openProfileChoice}
        onClose={() => setOpenProfileChoice(false)}
        loginResponse={authInfo}
      />
    </Container>
  );
}
