import { ActionIcon, Title, Tooltip } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconInfoCircle } from '@tabler/icons-react';
import * as React from 'react';
import { ReactNode } from 'react';

type Props = {
  title?: string;
  message: string | ReactNode;
  type?: 'modal' | 'hover';
};

export default function MoreInfoButtonModal({
  title = '',
  message,
  type = 'modal',
}: Props) {
  function openModalMessage() {
    modals.open({
      title: (
        <Title size={'h3'} component="p">
          {title}
        </Title>
      ),
      children: message,
      size: 'xl',
    });
  }

  return (
    <Tooltip label={message} disabled={type === 'modal'}>
      <ActionIcon
        size={'xs'}
        variant={'subtle'}
        onClick={type === 'modal' ? openModalMessage : undefined}
      >
        <IconInfoCircle />
      </ActionIcon>
    </Tooltip>
  );
}
