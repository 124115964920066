import { Stack } from '@mantine/core';
import {
  DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';

import CustomMonthPickerInput from '../../../../components/CustomMonthPickerInput';
import { useAppBar } from '../../../../contexts/AppBarProvider';
import { useAuth } from '../../../../contexts/AuthProvider';
import useCompany from '../../../../hooks/useCompany';
import useFetchDivisions from '../../../../hooks/useFetchDivisions';
import CompanyService from '../../../../services/CompanyService';
import ExpenseReportService from '../../../../services/ExpenseReportService';
import { BuiltInRoles } from '../../../../types/api/response/role';
import {
  ExpenseStatusState,
  type UserExpenseReportSummaryDetail,
} from '../../../../types/types';
import { hasRole } from '../../../../utils/authorization';
import { initPickerDate } from '../../../../utils/initPickerDate';
import { NO_WORK_UNIT } from '../../../../variables/GlobalVariables';
import EndOfMonthExpenseReportsTable from './EndOfMonthExpenseReportsTable';

export type EndOfMonthExpenseReportRow = UserExpenseReportSummaryDetail & {
  userId: string;
  userFullname: string;
  statusState: ExpenseStatusState;
  userDivisionName?: string;
};

export default function ByMonth() {
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const { divisions } = useFetchDivisions({ companyId });
  const {
    setIsAppBarUsed,
    setDivisionsToSelect,
    setAppBarSelectedDivisions,
    appBarSelectedDivisions,
  } = useAppBar();
  const [selectedDate, setSelectedDate] = useState<
    DateValue | DatesRangeValue | Date[]
  >(initPickerDate());

  const divisionsId: string[] = useMemo(() => {
    if (!divisions) {
      return [];
    }

    if (!appBarSelectedDivisions?.length) {
      return divisions.map((item) => item.id);
    }

    const result: string[] = [];
    appBarSelectedDivisions.forEach((selectedDivision: string) => {
      const findDivision = divisions.find(
        (division) => division.name === selectedDivision
      );
      if (findDivision) {
        result.push(findDivision.id);
      }
    });

    return result;
  }, [divisions, appBarSelectedDivisions]);

  const {
    data: expenseReports,
    refetch: refetchExpenseReports,
    isFetching: isExpenseReportsLoading,
  } = useQuery({
    enabled: !!companyId && !!divisionsId.length,
    queryKey: [
      'ExpenseReportService.getExpenseReportsByDivisionsAndMonth',
      companyId,
    ],
    queryFn: () =>
      ExpenseReportService.getExpenseReportsByDivisionsAndMonth(companyId, {
        // @ts-ignore
        monthConcerned: dayjs(selectedDate)
          .set('date', 15)
          .format('DD/MM/YYYY'),
        divisionIds: divisionsId,
      }),
  });

  useEffect(() => {
    refetchExpenseReports();
  }, [selectedDate, divisionsId]);

  const { data: employees } = useQuery({
    queryKey: ['CompanyService.listEmployees', companyId],
    queryFn: () =>
      CompanyService.listEmployees(companyId, ['fullname', 'divisionId']),
  });

  useEffect(() => {
    setIsAppBarUsed(true);
    return function cleanup() {
      setIsAppBarUsed(false);
    };
  }, []);

  useEffect(() => {
    buildAppbarDivisions();
  }, [hasRole(BuiltInRoles.ADMINISTRATOR, user), divisions]);

  const rows: EndOfMonthExpenseReportRow[] = useMemo(() => {
    if (!employees?.length || !expenseReports?.length) return [];

    const result: EndOfMonthExpenseReportRow[] = [];
    expenseReports.forEach((item) => {
      const creator = item.values?.[0]?.creator;
      if (creator) {
        const employee = employees.find((item) => item.id === creator);
        if (employee) {
          const division = divisions.find(
            (item) => item.id === employee.divisionId
          );

          let _statusState = ExpenseStatusState.REJECTED;

          if (item.waiting > 0) {
            _statusState = ExpenseStatusState.WAITING;
          } else if (item.processed > 0) {
            _statusState = ExpenseStatusState.PROCESSED;
          } else if (item.refundProcessing > 0) {
            _statusState = ExpenseStatusState.VALID;
          } else if (item.refunded > 0) {
            _statusState = ExpenseStatusState.REFUNDED;
          }

          result.push({
            ...item,
            userId: employee.id,
            userFullname: employee.fullname!,
            userDivisionName: division?.name,
            statusState: _statusState,
          });
        }
      }
    });

    return result;
  }, [expenseReports, employees, divisions]);

  function buildAppbarDivisions() {
    let _divisions = divisions.filter(
      (division) => division.name !== NO_WORK_UNIT
    );
    if (!hasRole(BuiltInRoles.ADMINISTRATOR, user)) {
      _divisions = _divisions.filter(
        (division) =>
          division.n1 === user.id ||
          division.n2 === user.id ||
          division.assistant === user.id
      );
    }
    const divisionsNames = _divisions.map((division) => division.name);
    setDivisionsToSelect(divisionsNames);
    setAppBarSelectedDivisions(divisionsNames);
  }

  return (
    <Stack>
      <CustomMonthPickerInput
        key={'month-picker-end-of-month-expense-reports'}
        value={selectedDate}
        onChange={setSelectedDate}
      />
      <EndOfMonthExpenseReportsTable
        rows={rows}
        isExpenseReportsLoading={isExpenseReportsLoading}
        selectedDate={selectedDate}
      />
    </Stack>
  );
}
