import { Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Group, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconCoins, IconEye, IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import ExpenseStatusBadge from '../../../../components/badges/ExpenseStatusBadge';
import UnitBadge from '../../../../components/badges/UnitBadge';
import CustomActionIconFile from '../../../../components/CustomActionIconFile';
import CustomTooltip from '../../../../components/CustomTooltip';
import GoToUserButton from '../../../../components/GoToUserButton/GoToUserButton';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import useCompany from '../../../../hooks/useCompany';
import useFetchDivisions from '../../../../hooks/useFetchDivisions';
import CompanyService from '../../../../services/CompanyService';
import ExpenseReportService from '../../../../services/ExpenseReportService';
import type { DivisionResponse } from '../../../../types/api/response/division';
import type { ExpenseReportObject } from '../../../../types/types';
import { handleOpenExpenseReportAttachmentFile } from '../../../../utils/attachmentFile';
import { getTotalHT, getTotalVAT } from '../../../../utils/expenseReport';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import {
  getHiddenColumns,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';
import ExpenseReportRequestForm from '../../../expense-reports/components/ExpenseReportRequestForm';

const componentName = 'ExpenseReportsToReimburse';

type Row = ExpenseReportObject & { fullname?: string; unit?: DivisionResponse };

export default function ToReimburse() {
  const { user, access_token } = useAuth();
  const { t, lang } = useI18n();
  const { id: companyId } = useCompany(user);

  const { divisions } = useFetchDivisions({ companyId });

  const { data: users, isLoading: isLoadingUsers } = useQuery({
    queryKey: ['CompanyService.listEmployees', companyId],
    queryFn: () =>
      CompanyService.listEmployees(companyId, ['fullname', 'divisionId']),
  });

  const {
    data,
    isLoading: isLoadingExpenseReports,
    refetch,
  } = useQuery({
    queryKey: ['ExpenseReportService.getToReimburse', companyId],
    queryFn: () => ExpenseReportService.getToReimburse(companyId),
  });

  const { mutate: reimburse } = useMutation({
    mutationFn: (expenseReportId: string) =>
      ExpenseReportService.reimburse(companyId, [expenseReportId]),
    onSuccess: () => {
      refetch();
      showNotification({
        id: 'reimburse-expense-report-successful',
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: 'reimburse-expense-report-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const rows: Row[] = useMemo(() => {
    if (!data?.length || !users?.length || !divisions?.length) return [];

    const _rows: Row[] = [...data];
    _rows.forEach((expenseReport) => {
      const whoUser = users.find((_user) => _user.id === expenseReport.creator);
      if (whoUser) {
        expenseReport.fullname = whoUser.fullname;
        expenseReport.unit = divisions.find(
          (item) => item.id === whoUser.divisionId
        );
      }
    });

    return _rows;
  }, [data, users, divisions]);

  const columns: DataTableColumn<Row>[] = useMemo(
    () => [
      {
        accessor: 'fullname',
        title: t('w.fullname'),
        sortable: true,
        ellipsis: true,
        render: ({ fullname, creator }) => (
          <GoToUserButton userId={creator} userFullname={fullname as string} />
        ),
      },
      {
        accessor: 'unit',
        title: t('w.unit'),
        sortable: true,
        ellipsis: true,
        render: ({ unit }) => <UnitBadge unit={unit?.name} />,
      },
      {
        accessor: 'dateOfExpense',
        title: t('expenseReport.dateOfExpense'),
        sortable: true,
        ellipsis: true,
        render: ({ dateOfExpense }) =>
          dayjs(dateOfExpense).format('DD/MM/YYYY'),
      },
      {
        accessor: 'type',
        title: t('w.type'),
        sortable: true,
        ellipsis: true,
        render: ({ type }) => t('expenseReport.type.' + type.toLowerCase()),
      },
      {
        accessor: 'label',
        title: t('w.wording'),
        sortable: true,
        ellipsis: true,
        render: ({ label }) => t('expenseReport.label.' + label),
      },
      {
        accessor: 'amountHT',
        title: t('w.amountHT'),
        sortable: true,
        ellipsis: true,
        render: ({ additionValueVATList, amount }) =>
          (getTotalHT(additionValueVATList, amount) || 0).toFixed(2),
      },
      {
        accessor: 'totalVAT',
        title: t('w.totalVAT'),
        sortable: true,
        ellipsis: true,
        render: ({ additionValueVATList }) =>
          (getTotalVAT(additionValueVATList) || 0).toFixed(2),
      },
      {
        accessor: 'amount',
        title: t('w.amountTTC'),
        sortable: true,
        ellipsis: true,
        render: ({ amount }) => amount,
      },
      {
        accessor: 'status.state',
        title: t('w.status'),
        sortable: true,
        ellipsis: true,
        render: ({ status }) => <ExpenseStatusBadge status={status} />,
      },
      {
        accessor: 'comment',
        title: t('w.comment'),
        sortable: true,
        ellipsis: true,
        width: 200,
        render: ({ comment }) => comment,
      },
      {
        accessor: 'attachment',
        title: t('expenseReport.receipt'),
        sortable: true,
        ellipsis: true,
        render: ({ companyId, id, attachment }) => {
          let extension = '';
          if (attachment) {
            const words = attachment.split('/');
            extension = words[words.length - 1]?.split('.')?.pop() || '';
          }
          return (
            <Group>
              {attachment && (
                <CustomTooltip label={t('w.seeAttachment')}>
                  <CustomActionIconFile
                    fileName={attachment}
                    onClick={() =>
                      handleOpenExpenseReportAttachmentFile(
                        companyId,
                        id,
                        extension,
                        access_token,
                        t
                      )
                    }
                  />
                </CustomTooltip>
              )}
            </Group>
          );
        },
      },
      {
        accessor: 'internal',
        title: t('w.internalGuest'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'external',
        title: t('w.externalGuest'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        width: 100,
        render: (expenseReport) => {
          return (
            <Group gap="xs" justify="end" wrap={'nowrap'}>
              <CustomTooltip label={t('w.see')}>
                <ActionIcon
                  variant={'subtle'}
                  size="sm"
                  color="green"
                  onClick={() =>
                    handleOpenExpenseReportModalClick(expenseReport)
                  }
                >
                  <IconEye size={16} />
                </ActionIcon>
              </CustomTooltip>
              <CustomTooltip label={t('w.markAsReimburse')}>
                <ActionIcon
                  variant={'subtle'}
                  size="sm"
                  onClick={() => reimburse(expenseReport.id)}
                >
                  <IconCoins size={18} />
                </ActionIcon>
              </CustomTooltip>
            </Group>
          );
        },
      },
    ],
    [users]
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: 'fullname', direction: 'asc' },
    search: {
      accessor: 'fullname',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [],
    }),
    updateHiddenColumns,
  });

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  function handleOpenExpenseReportModalClick(expenseReport: Row) {
    modals.open({
      modalId: `expense-report-${expenseReport.id}-to-see-modal`,
      title: (
        <Title size={'h3'} component="p">{`${t('w.expenseReport')} - ${
          expenseReport.fullname
        }`}</Title>
      ),
      fullScreen: true,
      children: (
        <ExpenseReportRequestForm
          closeModal={() =>
            modals.close(
              `expense-report-${expenseReport.id}-to-see-or-update-modal`
            )
          }
          expenseReport={expenseReport}
          edit={false}
          userProfile={user} // user devrait être celui de la ndf, ici edit est false, ça ne pose pas de probleme car// on ne maj pas la donnée
        />
      ),
    });
  }

  return (
    <Table
      pinFirstColumn
      pinLastColumn
      options={options}
      rows={rows}
      columns={columns}
      lang={lang}
      fetching={isLoadingUsers || isLoadingExpenseReports}
      withTableBorder={false}
      height={'calc(100vh - 200px)'}
    />
  );
}
