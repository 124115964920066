import { Accordion, Group, Loader, Select, Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { useMemo, useState } from 'react';

import { useAuth } from '../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../contexts/I18nProvider';
import useCompany from '../../../../../hooks/useCompany';
import CompanyService from '../../../../../services/CompanyService';
import { ModuleName } from '../../../../../types/api/response/module';
import UserWeekRepartitionForm from '../../../../user-profile/components/UserWeekRepartitionForm';

export default function UserWeeklySchedule() {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const [selectedUserId, setSelectedUserId] = useState<string>(user.id);

  const { data } = useQuery({
    queryKey: ['CompanyService.listEmployees', companyId],
    queryFn: () =>
      CompanyService.listEmployees(companyId, [
        'fullname',
        'firstname',
        'lastname',
        'lastLoginDate',
        'securityRoleId',
        'divisionId',
        'companyId',
        'valid',
        'mail',
        'employment',
        'identifiantsCompany',
        'paidFeatures',
        'fixedPriceManager',
        'onBoardingId',
      ]),
  });

  const userProfile = useMemo(() => {
    return data?.find((elt) => elt.id === selectedUserId);
  }, [data, selectedUserId]);

  if (!userProfile) {
    return <Loader />;
  }

  return (
    <Accordion variant="filled" mt="sm">
      <Accordion.Item value="default">
        <Accordion.Control>
          <Text c="red" fw="bold">
            {t('w.help')}
          </Text>
        </Accordion.Control>
        <Accordion.Panel>
          <Group>
            <Text size="sm" fw="bold">
              {t('w.weeklyScheduleOf')}
            </Text>
            <Select
              searchable
              allowDeselect={false}
              data={data
                ?.filter(
                  (item) =>
                    item.valid &&
                    item.paidFeatures
                      ?.filter((pf) => pf.active)
                      ?.map((pf) => pf.name)
                      .includes(ModuleName.DailyWork)
                )
                .map((elt) => ({ value: elt.id, label: elt.fullname! }))}
              value={selectedUserId}
              onChange={(value) => setSelectedUserId(value!)}
            />
          </Group>
          <UserWeekRepartitionForm
            key={userProfile.id}
            userProfile={userProfile}
            editable={false}
          />
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
}
