import { Table } from '@ckprivate/ckf-ui';
import { Fieldset, Flex, Text } from '@mantine/core';
import type {
  DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { useQueries } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { DataTableColumn } from 'mantine-datatable';
import * as React from 'react';
import { useMemo } from 'react';

import GoToUserButton from '../../../../../components/GoToUserButton/GoToUserButton';
import MoreInfoButtonModal from '../../../../../components/MoreInfoButtonModal';
import { useI18n } from '../../../../../contexts/I18nProvider';
import CounterUserService from '../../../../../services/CounterUserService';
import { CounterTypeResponse } from '../../../../../types/api/response/counterType';
import { CounterUserResponse } from '../../../../../types/api/response/counterUser';
import {
  CounterStr,
  UserPayslipRequest,
  UserPayslipResponse,
} from '../../../../../types/types';
import { getTranslatedKey } from '../../../../../utils/counterTypesFormatter';
import IncrementForm from './IncrementForm';

type Props = {
  selectedEmployees: UserPayslipResponse[];
  employeesCounters: UserPayslipRequest[];
  activeCounterTypes: CounterTypeResponse[];
  selectedDate: DateValue | DatesRangeValue | Date[];
};

export type Row = UserPayslipResponse & {
  counters: Counter[];
};

type Counter = CounterUserResponse & {
  extractToPayslip: boolean;
  key: string;
  next?: CounterStr;
};

export default function UsersManualCounters({
  selectedEmployees,
  employeesCounters,
  activeCounterTypes,
  selectedDate,
}: Props) {
  const { t, lang } = useI18n();
  const usersCountersQueries = useQueries({
    queries: selectedEmployees.map((user) => {
      return {
        queryKey: ['CounterUserService.getByUser', user.userId],
        queryFn: () => CounterUserService.getByUser(user.userId),
      };
    }),
  });
  // @ts-ignore
  const endMonthDate = dayjs(selectedDate).endOf('month');

  const dataArray = usersCountersQueries
    .filter((item) => !!item && !!item.data)
    .map((item) => item.data);

  const rows: Row[] = selectedEmployees.map((employee) => {
    const userCounters = dataArray.find(
      (elt) => !!elt?.length && elt[0].userId === employee.userId
    );

    const counters: Counter[] = (userCounters || []).map((userCounter) => {
      const counterType = activeCounterTypes.find(
        (counterType) =>
          counterType.counterType.id === userCounter.counterTypeId
      );

      const employeeCounter = employeesCounters.find(
        (employeeCounter) => employeeCounter.userId === employee.userId
      );

      return {
        ...userCounter,
        extractToPayslip: Boolean(counterType?.counterType?.extractToPayslip),
        key: counterType?.counterType?.key || '',
        next: employeeCounter?.counter?.find(
          (ctr) => ctr.counterTypeId === counterType?.counterType?.id
        ),
      };
    });

    return {
      ...employee,
      counters,
    };
  });

  const columns: DataTableColumn<Row>[] = useMemo(() => {
    const startColumns: DataTableColumn<Row>[] = [
      {
        accessor: 'fullname',
        title: t('w.employee'),
        sortable: true,
        ellipsis: true,
        render: ({ userId, fullname }) => (
          <GoToUserButton userId={userId} userFullname={fullname} />
        ),
      },
    ];

    const endColumns: DataTableColumn<Row>[] = [];

    if (activeCounterTypes) {
      const _counterTypes = activeCounterTypes.map(
        (_counterType) => _counterType.counterType
      );
      _counterTypes.forEach((_counterType) => {
        endColumns.push({
          accessor: `counter.${_counterType.key}`,
          title: getTranslatedKey(t, _counterType.key),
          sortable: true,
          ellipsis: true,
          render: ({ userId, counters, counter }) => {
            const find = counters?.find(
              (counter) => counter.key === _counterType.key
            );
            const extractToPayslip = Boolean(find?.extractToPayslip);

            const oldData = (
              <Fieldset legend={t('w.before')}>
                <Text size="xs">
                  {t(
                    'counterUser.manual.soldeValueDate',
                    dayjs(find?.date).format('DD/MM/YYYY'),
                    find?.value?.toFixed(2)
                  )}
                </Text>
                <Text size="xs">
                  {t(
                    'counterUser.manual.daysTakenValueDate',
                    dayjs(find?.date).format('DD/MM/YYYY'),
                    find?.consumes?.toFixed(2)
                  )}
                </Text>
                <Text size="xs">
                  {t('counterUser.manual.daysWait', find?.wait?.toFixed(2))}
                </Text>
                <Text size="xs">
                  {t(
                    'counterUser.manual.soldeForecast',
                    find?.solde?.toFixed(2)
                  )}
                </Text>
              </Fieldset>
            );

            if (extractToPayslip) {
              const isNoValueDetected = counter[_counterType.key] === undefined;
              return (
                <Flex p={2} gap={0}>
                  {oldData}
                  <Fieldset legend={t('w.after')}>
                    {isNoValueDetected ? (
                      <Text size="xs" fw="bold" c="orange">
                        {t('counterUser.manual.noValueDetected')}
                        <MoreInfoButtonModal
                          message={
                            <Text size="sm">
                              {t('counterUser.manual.noValueDetectedWarning')}
                              <br />
                              <br />
                              {t('counterUser.manual.noValueDetectedWarning1')}
                              <br />
                              {t('counterUser.manual.noValueDetectedWarning2')}
                            </Text>
                          }
                          type="hover"
                        />
                      </Text>
                    ) : (
                      <Text size="xs" fw="bold" c="green">
                        {t('w.balance')} : {counter[_counterType.key]}{' '}
                        <MoreInfoButtonModal
                          message={t('counterUser.manual.valueDetected')}
                          type="hover"
                        />{' '}
                        {t('w.at').toLowerCase()}{' '}
                        {endMonthDate.format('DD/MM/YYYY')}
                      </Text>
                    )}
                  </Fieldset>
                </Flex>
              );
            }

            return (
              <Flex p={2} gap={0}>
                {oldData}
                <Fieldset legend={t('w.after')}>
                  <Text size="xs" fw="bold" c="orange">
                    {t('counterUser.manual.dontForgotToManualUpdate')}
                  </Text>
                  <IncrementForm
                    endMonthDate={endMonthDate}
                    counterType={_counterType}
                    userId={userId}
                  />
                </Fieldset>
              </Flex>
            );
          },
        });
      });
    }

    return startColumns.concat(endColumns);
  }, [activeCounterTypes, rows]);

  return (
    <Table
      pinFirstColumn
      rows={rows}
      columns={columns}
      idAccessor={'userId'}
      lang={lang}
      height={'calc(100vh - 360px)'}
    />
  );
}
