import {
  FilterOption,
  FilterOptionChoice,
  Options,
  Table,
} from '@ckprivate/ckf-ui';
import { ActionIcon, Button, Title } from '@mantine/core';
import type {
  DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { modals } from '@mantine/modals';
import {
  IconEye,
  IconEyeOff,
  IconFileTypePdf,
  IconUsers,
} from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import { DataTableColumn } from 'mantine-datatable';
import React, { useEffect, useMemo, useState } from 'react';

import UnitBadge from '../../../../../components/badges/UnitBadge';
import CustomTooltip from '../../../../../components/CustomTooltip';
import GoToUserButton from '../../../../../components/GoToUserButton/GoToUserButton';
import { useAuth } from '../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../contexts/I18nProvider';
import useFetchDivisions from '../../../../../hooks/useFetchDivisions';
import CounterTypeService from '../../../../../services/CounterTypeService';
import { DivisionResponse } from '../../../../../types/api/response/division';
import type {
  PayslipMonthResponse,
  UserPayslipResponse,
} from '../../../../../types/types';
import { handleOpenPayslipPdfFile } from '../../../../../utils/attachmentFile';
import { getTranslatedKey } from '../../../../../utils/counterTypesFormatter';
import { milliToFullDateString } from '../../../../../utils/format';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../../utils/optionsPreferences';
import { NO_WORK_UNIT } from '../../../../../variables/GlobalVariables';
import NotifyPayslipsEmployeesForm from './NotifyPayslipsEmployeesForm';

const componentName = 'ValidatedPayslipsTable';

type Row = UserPayslipResponse & { division?: DivisionResponse };

type Props = {
  payslipsMonthInfo: PayslipMonthResponse | undefined;
  companyId: string;
  selectedDate: DateValue | DatesRangeValue | Date[];
};

export default function ValidatedPayslipsTable({
  payslipsMonthInfo,
  companyId,
  selectedDate,
}: Props) {
  const { t, lang } = useI18n();
  const { user, access_token } = useAuth();
  const { divisions } = useFetchDivisions({ companyId });
  const [selectedEmployees, setSelectedEmployees] = useState<any>([]);
  const { data: counterTypes } = useQuery({
    queryKey: ['CounterTypeService.list', companyId],
    queryFn: () => CounterTypeService.list(companyId),
  });

  const rightCustomActions = useMemo(
    () => [
      <Button
        disabled={selectedEmployees.length === 0}
        key={'notify-collaborators'}
        onClick={handleOpenNotifyEmployeesModal}
        rightSection={<IconUsers size={15} />}
      >
        {t('w.notify')}
      </Button>,
    ],
    [selectedEmployees]
  );

  const rows = useMemo(() => {
    if (!payslipsMonthInfo?.ok?.length) {
      return [];
    }

    return payslipsMonthInfo.ok.map((userPayslip) => ({
      ...userPayslip,
      division: cloneDeep(
        divisions?.find((elt) => elt.id === userPayslip.divisionId)
      ),
    }));
  }, [payslipsMonthInfo, divisions]);

  const columns: DataTableColumn<Row>[] = useMemo(() => {
    const startColumns: DataTableColumn<Row>[] = [
      {
        accessor: 'fullname',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
        render: ({ userId, fullname }) => (
          <GoToUserButton userId={userId} userFullname={fullname} />
        ),
      },
      {
        accessor: 'division.name',
        title: user.company?.divisionLabel
          ? user.company.divisionLabel
          : t('w.unit'),
        sortable: true,
        ellipsis: true,
        render: ({ division }) =>
          !!division?.name ? <UnitBadge unit={division.name} /> : '',
      },
    ];

    const middleColumns: DataTableColumn<Row>[] = [];
    if (counterTypes) {
      const _counterTypes = counterTypes
        .map((item) => item.counterType)
        .filter((elt) => elt.active && elt.extractToPayslip);
      _counterTypes.forEach((item) => {
        middleColumns.push({
          accessor: `counter.${item.key}`,
          title: getTranslatedKey(t, item.key),
          sortable: true,
          ellipsis: true,
        });
      });
    }

    const endColumns: DataTableColumn<Row>[] = [
      {
        accessor: 'siret',
        title: t('w.siret'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'netAmount',
        title: t('w.netAmount'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'grossAmount',
        title: t('w.grossAmount'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'userId',
        title: t('w.payslip'),
        sortable: true,
        ellipsis: true,
        render: ({ userId, fullname }) => (
          <CustomTooltip label={t('w.see')}>
            <ActionIcon
              variant={'subtle'}
              onClick={() =>
                handleOpenPayslipPdfFile(
                  userId,
                  fullname,
                  selectedDate,
                  payslipsMonthInfo,
                  access_token,
                  t
                )
              }
            >
              <IconFileTypePdf />
            </ActionIcon>
          </CustomTooltip>
        ),
      },
      {
        accessor: 'visible',
        title: t('w.visible'),
        sortable: true,
        ellipsis: true,
        render: ({ visible }) =>
          visible ? (
            <IconEye color={'var(--mantine-color-hifivework-3)'} />
          ) : (
            <IconEyeOff color={'var(--mantine-color-hifivework-3)'} />
          ),
      },
      {
        accessor: 'notification',
        title: t('w.notificationDate'),
        sortable: true,
        ellipsis: true,
        render: ({ notification }) =>
          notification && milliToFullDateString(notification),
      },
    ];

    return startColumns.concat(middleColumns.concat(endColumns));
  }, [rows, counterTypes]);

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'fullname',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [''],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'division.name',
          label: user.company?.divisionLabel
            ? user.company.divisionLabel
            : t('w.unit'),
          choices: [],
          selectedChoices: [],
        },
        {
          accessor: 'visible',
          label: 'Visible',
          choices: [
            { value: 'true', label: 'Visible' },
            { value: 'false', label: `${t('w.no')}-visible` },
          ],
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  useEffect(() => {
    if (divisions?.length) {
      const _unitNames: FilterOptionChoice[] = [];
      divisions
        .filter((div) => div.name !== NO_WORK_UNIT)
        .forEach((item) => {
          _unitNames.push({
            value: item.id,
            label: item.name,
          });
        });
      const _options: Options = { ...options };
      if (!!_options?.filter?.[0]?.choices) {
        _options.filter[0].choices = _unitNames;
        _options.filter[0].selectedChoices = _unitNames.filter((choice) =>
          _options?.filter?.[0]?.selectedChoices?.some(
            (selected) => selected.value === choice.value
          )
        );
      }
      setOptions(_options);
    }
  }, [divisions]);

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  function handleOpenNotifyEmployeesModal() {
    modals.open({
      modalId: 'notify-employees-modal',
      title: (
        <Title size={'h3'} component="p">
          {t('w.validateAndNotify')}
        </Title>
      ),
      size: 'xxl',
      children: (
        <NotifyPayslipsEmployeesForm
          selectedEmployees={selectedEmployees}
          selectedDate={selectedDate}
          companyId={companyId}
          closeModal={() => modals.close('notify-employees-modal')}
        />
      ),
    });
  }

  return (
    <Table
      pinFirstColumn
      onSelect={(employee) => setSelectedEmployees(employee)}
      rows={rows}
      columns={columns}
      options={options}
      idAccessor={'userId'}
      lang={lang}
      height={'calc(100vh - 360px)'}
      rightCustomActions={rightCustomActions}
    />
  );
}
