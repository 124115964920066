import {
  ActionIcon,
  Button,
  Flex,
  Group,
  LoadingOverlay,
  Stack,
  Switch,
  Text,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import {
  IconArrowRight,
  IconCheck,
  IconCirclePlus,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';

import LabelText from '../../../../../components/LabelText/LabelText';
import { useAuth } from '../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../contexts/I18nProvider';
import useCompany from '../../../../../hooks/useCompany';
import DailyWorkService from '../../../../../services/DailyWorkService';
import type { DailyWorkWeeklyTemplateRequest } from '../../../../../types/api/payload/dailyWork';
import type { DailyWorkWeeklyTemplateResponse } from '../../../../../types/api/response/dailyWork';
import handleErrorMessage from '../../../../../utils/handleErrorMessage';
import UserWeeklySchedule from './UserWeeklySchedule';

type Props = {
  template: DailyWorkWeeklyTemplateResponse;
  refetchWeeklyTemplatesData: () => void;
  onClose: () => void;
  isEditing?: boolean;
};

export default function WeeklyTemplateForm({
  template,
  refetchWeeklyTemplatesData,
  onClose,
  isEditing,
}: Props) {
  const { user } = useAuth();
  const canEdit = !isEditing || template.creatorId === user.id;
  const { t } = useI18n();
  const { id: companyId } = useCompany(user);

  const form = useForm<DailyWorkWeeklyTemplateRequest>({
    initialValues: { ...template },
  });

  useEffect(() => {
    if (isEditing) {
      form.values.weeklyPeriods.forEach((item, index) => {
        const findTemplateDay = template.weeklyPeriods.find(
          (weeklyPeriod) => weeklyPeriod.day === item.day
        );
        if (findTemplateDay) {
          if (findTemplateDay.day === item.day) {
            form.setFieldValue(
              `weeklyPeriods.${index}.period`,
              findTemplateDay.period
            );
            form.setFieldValue('name', template.name);
          }
        }
      });
    }
  }, [isEditing]);

  const {
    mutate: updateWeeklyTemplate,
    isLoading: isUpdateWeeklyTemplateLoading,
  } = useMutation({
    mutationFn: (variables: DailyWorkWeeklyTemplateRequest) =>
      DailyWorkService.updateWeeklyTemplate(companyId, template.id, variables),
    onSuccess: (data) => {
      const { id, name } = data;
      refetchWeeklyTemplatesData();
      onClose();
      showNotification({
        id: `update-weekly-template-${id}-successful`,
        title: t('w.success'),
        message: `Modèle ${name} ${t('w.updated').toLowerCase()}`,
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: `update-weekly-template-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });
  const {
    mutate: createWeeklyTemplate,
    isLoading: isCreateWeeklyTemplateLoading,
  } = useMutation({
    mutationFn: (variables: DailyWorkWeeklyTemplateRequest) =>
      DailyWorkService.createWeeklyTemplate(companyId, variables),
    onSuccess: (data) => {
      const { id, name } = data;
      refetchWeeklyTemplatesData();
      onClose();
      showNotification({
        id: `create-weekly-template-${id}-successful`,
        title: t('w.success'),
        message: `Modèle ${name} ${t('w.created').toLowerCase()}`,
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: `create-weekly-template-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleWeeklyTemplateSubmit(values: DailyWorkWeeklyTemplateRequest) {
    if (isEditing) {
      updateWeeklyTemplate({ ...values });
    } else {
      createWeeklyTemplate({ ...values });
    }
  }

  return (
    <>
      <form
        onSubmit={form.onSubmit((values) => handleWeeklyTemplateSubmit(values))}
      >
        <LoadingOverlay
          visible={
            isUpdateWeeklyTemplateLoading || isCreateWeeklyTemplateLoading
          }
        />
        <Stack>
          <Flex align={'flex-end'} justify={'space-between'}>
            <TextInput
              disabled={!canEdit}
              label={<LabelText text={t('w.modelName')} />}
              required
              {...form.getInputProps('name')}
            />
            <Switch
              disabled={!canEdit}
              labelPosition="left"
              label={<LabelText text={t('w.sharedTemplate')} />}
              checked={form.values.shared}
              onLabel={t('w.yes')}
              offLabel={t('w.no')}
              size="md"
              {...form.getInputProps('shared')}
            />
          </Flex>
          <Title size={'h4'} c={'dimmed'}>
            {t('w.weeklyPeriods')}
          </Title>
          <Flex
            direction={{ base: 'column', lg: 'row' }}
            justify={'space-around'}
            align={'flex-start'}
            gap="sm"
          >
            {form.values.weeklyPeriods.map((item, weeklyPeriodIndex) => (
              <Flex
                key={`${item.day}-${weeklyPeriodIndex}`}
                direction={'column'}
                gap={'md'}
                p={'xs'}
                styles={{
                  root: {
                    backgroundColor: 'var(--mantine-color-hifivework-0)',
                    borderRadius: 8,
                  },
                }}
              >
                <Group align={'center'} justify={'space-between'}>
                  <Title size={'h5'} c={'hifivework.6'}>
                    {t(`daysEnum.${item.day}`)}
                  </Title>
                  {canEdit && (
                    <Tooltip label={t('w.add')}>
                      <ActionIcon
                        variant={'subtle'}
                        size={'sm'}
                        onClick={() =>
                          form.insertListItem(
                            `weeklyPeriods.${weeklyPeriodIndex}.period`,
                            {
                              startDate: '00:00',
                              endDate: '00:00',
                            }
                          )
                        }
                      >
                        <IconCirclePlus />
                      </ActionIcon>
                    </Tooltip>
                  )}
                </Group>
                {item.period.map((item, periodIndex) => (
                  <Group
                    key={`group-${weeklyPeriodIndex}-${periodIndex}`}
                    gap={0}
                  >
                    <TimeInput
                      id={`start-${weeklyPeriodIndex}-${periodIndex}`}
                      label={
                        <Text fz={'sm'} fw={'bold'} c={'hifivework.3'}>
                          Début
                        </Text>
                      }
                      size="xs"
                      {...form.getInputProps(
                        `weeklyPeriods.${weeklyPeriodIndex}.period.${periodIndex}.startDate`
                      )}
                      disabled={!canEdit}
                    />
                    <IconArrowRight
                      style={{ marginTop: 'var(--mantine-spacing-md)' }}
                      color={'var(--mantine-color-hifivework-5)'}
                      size={15}
                    />
                    <TimeInput
                      id={`end-${weeklyPeriodIndex}-${periodIndex}`}
                      label={
                        <Text fz={'sm'} fw={'bold'} c={'hifivework.3'}>
                          Fin
                        </Text>
                      }
                      size="xs"
                      {...form.getInputProps(
                        `weeklyPeriods.${weeklyPeriodIndex}.period.${periodIndex}.endDate`
                      )}
                      disabled={!canEdit}
                    />
                    {canEdit && (
                      <ActionIcon
                        mt={'md'}
                        variant={'subtle'}
                        size={'xs'}
                        color={'red'}
                        onClick={() =>
                          form.removeListItem(
                            `weeklyPeriods.${weeklyPeriodIndex}.period`,
                            periodIndex
                          )
                        }
                      >
                        <IconTrash />
                      </ActionIcon>
                    )}
                  </Group>
                ))}
              </Flex>
            ))}
          </Flex>
          {canEdit && (
            <Flex mt="xl" justify={'flex-end'}>
              <Button type={'submit'}>
                {isEditing ? t('w.modify') : t('w.create')}
              </Button>
            </Flex>
          )}
        </Stack>
      </form>
      <UserWeeklySchedule />
    </>
  );
}
