import { Tabs } from '@mantine/core';
import React, { useMemo, useState } from 'react';

import { useI18n } from '../../../contexts/I18nProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import ByMonth from './byMonth/ByMonth';
import ToReimburse from './toReimburse/ToReimburse';

export default function EndOfMonthExpenseReports() {
  const { t } = useI18n();
  const [activeTab, setActiveTab] = useState<'byMonth' | 'toReimburse'>(
    'byMonth'
  );

  const tabs = useMemo(
    () => [
      {
        value: 'byMonth',
        label: t('w.byMonth'),
        children: <ByMonth />,
      },
      {
        value: 'toReimburse',
        label: t('w.toReimburse'),
        children: <ToReimburse />,
      },
    ],
    []
  );

  return (
    <ScreenWrapper title={t('w.expenseReports')}>
      <Tabs
        keepMounted={false}
        value={activeTab}
        onChange={(value) => setActiveTab(value as 'byMonth' | 'toReimburse')}
      >
        <Tabs.List>
          {tabs.map((tab, index) => (
            <Tabs.Tab key={`tab-${tab.value}-${index}`} value={tab.value}>
              {tab.label}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {tabs.map((tab, index) => (
          <Tabs.Panel
            key={`panel-${tab.value}-${index}`}
            value={tab.value}
            p={'md'}
          >
            {tab.children}
          </Tabs.Panel>
        ))}
      </Tabs>
    </ScreenWrapper>
  );
}
