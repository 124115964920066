import { Language } from '../../i18n';
import {
  type Address,
  type CommonEntity,
  DayOfWeek,
  type LocalDateTimeString,
} from '../common';
import { CompanyResponse, CountryAreaEnum, CountryEnum } from './company';
import type { DivisionResponse } from './division';
import { ModuleName } from './module';
import type { HfwSecurityRoleResponse } from './role';

export type UserResponse = CommonEntity & {
  mail?: string;
  firstname?: string;
  lastname?: string;
  fullname?: string;
  phone?: string;
  lang?: Language;
  employment?: string;
  valid?: boolean;
  termsAcceptance?: boolean;
  fixedPriceManager?: boolean;
  securityRoleId?: string;
  companyId?: string;
  divisionId?: string;
  firstManager?: string;
  secondManager?: string;
  onBoardingId?: string;
  identifiantsCompany?: IdentifiantCompany[];
  personalBreakDay?: UserBreakDay[];
  paidFeatures?: PaidFeature[];
  lastLoginDate?: LocalDateTimeString;
  company?: CompanyResponse;
  division?: DivisionResponse;
  nameOfN1?: string;
  nameOfN2?: string;
  myCompanies?: string[];
  role?: HfwSecurityRoleResponse;
  onBoarding?: OnBoardingResponse;
  histoPaie?: boolean;
};

export type IdentifiantCompany = {
  matricule?: string;
  codeCompany?: string;
};

type UserBreakDay = {
  morning: boolean;
  afternoon: boolean;
  day: keyof typeof DayOfWeek;
  repeat?: keyof typeof RepeatEnum;
};

enum RepeatEnum {
  EVERY_WEEKS = 'EVERY_WEEKS',
  EVERY_FIRST_OF_MONTH = 'EVERY_FIRST_OF_MONTH',
  EVERY_SECOND_OF_MONTH = 'EVERY_SECOND_OF_MONTH',
  EVERY_THIRD_OF_MONTH = 'EVERY_THIRD_OF_MONTH',
  EVEN_WEEKS = 'EVEN_WEEKS',
  ODD_WEEKS = 'ODD_WEEKS',
}

export type PaidFeature = {
  active: boolean;
  name: ModuleName;
};

export type OnBoardingResponse = {
  id: string;
  created: number;
  updated: number;
  userId: string;
  creatorId: string;
  onBoardingEmployee: OnBoardingEmployee;
  onBoardingCompany: OnBoardingCompanyResponse;
  onBoardingAccountant?: OnBoardingAccountantResponse;
  validationDate?: LocalDateTimeString;
  validatorId?: string;
  nameOfCreator: string;
  nameOfValidator?: string;
};

type OnBoardingEmployee = {
  lastUpdate?: LocalDateTimeString;
  inscriptionDate?: LocalDateTimeString;
  validationDate?: LocalDateTimeString;
};

type OnBoardingCompanyResponse = {
  updateDataDate?: OnBoardingDataResponse[];
  validationDate: OnBoardingDataResponse;
};

type OnBoardingDataResponse = {
  updater: string;
  nameOfUpdater: string;
  dateOfUpdate: LocalDateTimeString;
};

export type OnBoardingAccountantResponse = {
  validationDate?: OnBoardingDataResponse;
  registerInPayroll?: boolean;
};

export type UserPersonalSensitive = {
  id?: string;
  userId: string;
  identityCardNumber: string;
  identityCardFile: string;
  ssNumber: string;
  ssFile: string;
  handicappedWorker: boolean;
  iban: string;
  bic: string;
  residencePermitNumber: string;
  residencePermitFile: string;
  idFileAttachment?: boolean;
  ssFileAttachment?: boolean;
  residentPermitFileAttachment?: boolean;
};

export type UserPersonalFamily = {
  id?: string;
  userId: string;
  familyState?: FamilyState;
  numberDependents?: number;
  numberDependentsChildren?: number;
};

export enum FamilyState {
  married = 'married',
  single = 'single',
  civil_union = 'civil_union',
  divorced = 'divorced',
  separated = 'separated',
  widowed = 'widowed',
}

export type UserPersonalInfo = {
  id?: string | null;
  created?: string;
  updated?: string;
  userId: string;
  nationality?: string | null;
  gender?: Gender | string | null;
  phoneNumber?: string | null;
  birthday?: number | string | null;
  birthPlace?: string | null;
  birthDepartment?: string | null;
  address?: Address;
  birthCountry?: string | null;
  birthName?: string | null;
  commonName?: string | null;
};

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  UNKNOWN = 'UNKNOWN',
}

export type UserContractualInfoType = {
  id?: string;
  userId: string;
  payslipName: string;
  regime: ContractRegime;
  regimeLevel: string;
  otherRegime: string;
  professionalQualifications: string;
  contractType: ContractType;
  otherContractType: string;
  weeklyHours: number;
  monthlyHours: number;
  yearlyHours: number;
  grossPay: number;
  entryDate: number;
  exitDate: number;
  employmentAuthorizationDate: number;
  layoffAuthorizationDate: number;
  comment: string;
  contractFile: string;
  countryCode?: CountryEnum;
  countryArea?: CountryAreaEnum;
};

export enum ContractRegime {
  EXECUTIVE = 'EXECUTIVE',
  ETAM = 'ETAM',
  WORKER = 'WORKER',
  OTHER = 'OTHER',
}
export enum ContractType {
  CDI = 'CDI',
  CDD = 'CDD',
  CP = 'CP',
  CTT = 'CTT',
  CA = 'CA',
  OTHER = 'OTHER',
}

export type UserEmergencyContactType = {
  id?: string | null;
  userId?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  phone?: string;
  comment?: string;
};
